<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="От (в часах)">
          <b-form-input
            v-model="fromIntervalHours"
            autocomplete="false"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="до (в часах)">
          <b-form-input
            v-model="toIntervalHours"
            autocomplete="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <table class="table table-bordered">
        <tr>
          <td>
            {{ index.name }}
          </td>
          <template v-for="ratingPeriod in ratingPeriods">
            <td
            v-for="indexOption in ratingPeriod.indexOptions"
            :key="indexOption.id"
            >
              <b-form inline>
                <label :for="'indexOptionMin'+indexOption.id"> от </label>
                <b-form-input
                  :id="'indexOptionMin'+indexOption.id"
                  v-model="indexOption.min"
                  type="number"
                  min="0"
                  autocomplete="false"
                  placeholder="От"
                />
              </b-form>
              <b-form inline>
                <label :for="'indexOptionMax'+indexOption.id"> до </label>
                <b-form-input
                  :id="'indexOptionMax'+indexOption.id"
                  v-model="indexOption.max"
                  type="number"
                  min="0"
                  autocomplete="false"
                  placeholder="До"
                />
              </b-form>
            </td>
          </template>
        </tr>
        <tr>
          <td>
            Балл
          </td>
          <template v-for="ratingPeriod in ratingPeriods" class="d-flex">
            <td
              v-for="indexOption in ratingPeriod.indexOptions"
              :key="indexOption.id"
            >
              <b-form-input v-model="indexOption.value" />
            </td>
          </template>
        </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'LatenessRatingSetting',
  props: ['index'],
  data() {
    return {
      ratingPeriods: null,
    }
  },
  computed: {
    fromIntervalHours: {
      get: function() {
        return this.index.fromInterval / 60 / 60;
      },
      set: function(newValue) {
        this.index.fromInterval = newValue * 60 * 60;
      },
    },
    toIntervalHours: {
      get: function() {
        return (this.index.toInterval + 1) / 60 / 60;
      },
      set: function(newValue) {
        this.index.toInterval = newValue * 60 * 60 -1;
      },
    },
    ratingPeriodIndexes: {
      get: function () {
        return this.index.ratingPeriodIndexes;
      }
    }
  },
  mounted() {
    this.ratingPeriods = this.ratingPeriodIndexes;
  }
};
</script>

<style scoped>

</style>
